@import '../../../../scss/theme-bootstrap';

.carousel-formatter {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  transition: all .333s ease-in-out;
  position: relative;
  width: 100%;
  &__wrapper {
    .carousel-formatter__header {
      margin: 15px 0;
      p {
        line-height: inherit;
      }
    }
    .carousel-dots {
      text-align: center;
      height: auto;
    }
    .carousel__slide {
      transition: all .333s ease-in-out;
    }
    .slick-dots li {
      transition: all .333s ease-in-out;
      height: 4px;
      width: 45px;
      margin: 0 9px;
      button {
        height: inherit;
        width: inherit;
        &:before {
          height: inherit;
          width: inherit;
          border: none;
          border-radius: 0;
          background-color: $color-gray;
        }
      }
      &.slick-active {
        button:before {
          background-color: $color-black;
        }
      }
    }
    &.content-block {
      border: none;
    }
  }
  .icon--caret--left_v2,
  .icon--caret--right_v2 {
    background-color: $color-black;
    height: 50px;
    width: 30px;
    padding: 2px;
    opacity: .75;
    fill: $color-white;
    &:hover {
      opacity: 1;
      background-color: $color-black;
    }
    @include breakpoint($landscape-up) {
      width: 50px;
      padding: 12px 12px;
    }
  }
  .icon--caret--left_v2 {
    margin-#{$ldirection}: -5px;
  }
  .icon--caret--right_v2 {
    margin-#{$rdirection}: -5px;
  }
  &.homepage-carousel {
    .content-block {
      &__line {
        &--header {
          padding: 4px 0;
          .mantle-custom-text {
            font-size: get-rem(36px);
            line-height: get-line-height(36px, 44px);
            letter-spacing: -2px;
            padding-top: 3px;
          }
        }
        &--content {
          max-width: 100%;
          @include breakpoint($landscape-up) {
            max-width: 85%;
          }
          .mantle-custom-text {
            padding-bottom: 6px;
            font-size: get-rem(20px);
            line-height: get-line-height(20px, 24px);
          }
        }
      }
      &__link {
        .button {
          font-size: get-rem(16px);
          min-width: 134px;
          padding: 10px 5px;
        }
      }
    }
    .slick-arrow {
      .icon {
        width: 45px;
        padding: 12px;
      }
    }
    .slick-prev-button {
      .icon {
        margin-#{$ldirection}: -10px;
      }
    }
    .slick-next-button {
      .icon {
        margin-#{$rdirection}: -10px;
      }
    }
    .carousel-controls {
      top: 32%;
      @include breakpoint($landscape-up) {
        top: 50%;
      }
    }
    .carousel__slide {
      padding: 1px;
    }
    .hero-block {
      padding: 0 20px;
      @include breakpoint($landscape-up) {
        padding: 0 15px;
      }
      &__content-wrapper {
        padding: 0;
      }
      &__link-wrapper {
        padding: 10px 0;
      }
    }
    .carousel-formatter__container {
      margin: 0 -15px;
    }
  }
}
